.appBar {
	background-color: var(--oc-palette-primary-600);
	height: 56px;
	padding: 8px 32px 8px 16px;
	display: flex;
	flex-direction: row;
	z-index: 2;
}

.logo {
	margin: 8px 56px 8px 0px;
}

.avatarIcon {
	cursor: pointer;
}

.navItem {
	color: var(--oc-palette-dark-text-secondary);
}

.navItem:hover {
	color: var(--oc-palette-secondary-50);
	background-color: var(--oc-palette-secondary-600);
}

.switchButton {
	color: var(--oc-palette-dark-text-secondary);
	border: 1px solid transparent;
	margin-right: 20px;
}

.switchButton:hover {
	color: var(--oc-palette-dark-text-primary);
	background-color: var(--oc-palette-primary-600);
	border: 1px solid var(--oc-palette-secondary-400);
}

.switchButton:active {
	color: var(--oc-palette-dark-text-primary);
	background-color: var(--oc-palette-primary-600);
	border: 1px solid var(--oc-palette-dark-text-primary);
}
